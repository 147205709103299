.cardImgTop {
    margin: auto;
    width: auto;
    max-height: 150px;
}

.cardImgTopWrapper {
    height: 150px;
    display: flex;
    align-items: center;
}