.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #262230;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}